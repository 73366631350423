import React from 'react';
import HoverScrollAvatarCarousel from '../components/HoverScrollAvatarCarousel';
import SocialButtons from '../components/social';
import { Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <main className="flex flex-col h-16">
      <div className="flex flex-col items-center justify-center text-center space-y-4 mt-10 animate-fade-in px-4 sm:px-6 lg:px-8 flex-grow">
        <h1 className="text-3xl sm:text-5xl font-extrabold">Привет, это Манук!</h1>
        <p className="text-lg sm:text-2xl font-bold">Добро пожаловать на мой сайт.</p>
        <p className="text-gray-300 text-base sm:text-lg p-5">Не забудь ознакомиться с моими ссылками :)</p>

          <SocialButtons />
        
        <div className="mt-8 sm:mt-12">
          <p className="text-lg sm:text-xl font-semibold mt-6">Особая благодарность</p>
          <div className="flex items-center justify-center space-x-2 select-none">
            <HoverScrollAvatarCarousel />
          </div>
        </div>
      </div>

      <footer className="bg-[#182429] bg-opacity-40 p-5 w-full rounded-2xl">
        <div className="text-center">
          <p className="text-white font-inter text-lg select-none">
            Манук, Маня, Манукек, Манусрак, Потеря санити, Манукй_ • 2023-2024
          </p>
          <p className="text-white font-inter text-lg">
            Почта: thezhake@gmail.com
          </p>
          <p className="text-gray-400 font-inter text-lg">
            Вся размещенная информация на сайте не является публичной офертой. Информация на сайте будет изменяться.
          </p>
          <p className="text-gray-400 font-inter text-lg">
            Icons and etc. from 
            <a href="https://icons8.com/" className="text-gray-300 hover:text-gray-500"> icons8.com</a> and 
            <a href="https://www.svgrepo.com/" className="text-gray-300 hover:text-gray-500"> svgrepo.com</a>. 
            Check out the 
            <Link to="/secret" className="text-gray-300 hover:text-gray-500 transition duration-300 ease-in-out"> Secret</Link> page.
          </p>
        </div>
      </footer>
    </main>
  );
};

export default Home;
