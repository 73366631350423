import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar';
import Home from './pages/home';
import PageNotFound from "./pages/404";
import Secret from './pages/secret';

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <main className="pt-16 p-4 text-white">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route exact path="/" element={<Home />} />
          <Route
              path="*"
              element={<PageNotFound />}
          />
          <Route path="/secret" element={<Secret />} /> 
        </Routes>
      </main>
    </Router>
  );
};

export default App;
