import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'; // Для обработки HTML в Markdown

const Secret = () => {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [readmeContent, setReadmeContent] = useState('');

  const correctPassword = 'svasu#9988'; // Задайте ваш пароль

  // Проверка пароля
  const handleLogin = () => {
    if (password === correctPassword) {
      setIsAuthenticated(true);
      fetchReadme();
    } else {
      alert('Неверный пароль. Попробуйте снова.');
    }
  };

  // Загрузка файла README.md из public
  const fetchReadme = async () => {
    try {
      const response = await fetch('/public/README.md'); // Загружается из public
      if (response.ok) {
        const text = await response.text();
        setReadmeContent(text);
      } else {
        alert('Не удалось загрузить README.md');
      }
    } catch (error) {
      alert('Ошибка загрузки файла README.md');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#21343B] font-sans">
      {!isAuthenticated ? (
        <div className="w-full max-w-md p-8 bg-white shadow-lg rounded-lg">
          <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">
            Введите пароль
          </h2>
          <input
            type="password"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            onClick={handleLogin}
            className="w-full px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition"
          >
            Войти
          </button>
        </div>
      ) : (
        <div className="w-full max-w-4xl p-8 bg-white shadow-lg rounded-lg">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
            Содержимое README.md
          </h2>
          <div className="prose max-w-none">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{readmeContent}</ReactMarkdown>
          </div>
        </div>
      )}
    </div>
  );
};

export default Secret;
